import React from 'react';

const Facebook = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="facebook-ic">
        <g id="Group">
          <path
            id="Vector"
            d="M2 12.06C2.00056 14.456 2.85047 16.7732 4.39696 18.5952C5.94345 20.4172 8.08511 21.6244 10.437 21.9999V14.9678H7.9V12.06H10.44V9.84642C10.3839 9.32833 10.4419 8.80418 10.6098 8.31117C10.7777 7.81817 11.0515 7.36842 11.4116 6.99387C11.7718 6.61931 12.2095 6.32913 12.6936 6.14395C13.1778 5.95876 13.6965 5.88311 14.213 5.92237C14.963 5.93444 15.713 6.00186 16.453 6.1236V8.59776H15.189C14.9742 8.56945 14.7558 8.59008 14.5499 8.65812C14.3441 8.72615 14.1561 8.83986 13.9998 8.99084C13.8435 9.14182 13.7229 9.32621 13.647 9.53038C13.571 9.73455 13.5416 9.95328 13.561 10.1704V12.06H16.332L15.889 14.9688H13.561V21.9999C15.4486 21.6998 17.2112 20.8617 18.6402 19.5849C20.0691 18.3081 21.1045 16.6461 21.6238 14.7956C22.143 12.9451 22.1244 10.9836 21.5702 9.14345C21.0159 7.30326 19.9492 5.6614 18.4964 4.41226C17.0435 3.16311 15.2653 2.359 13.3724 2.09511C11.4794 1.83123 9.55092 2.11863 7.81525 2.92329C6.07958 3.72795 4.60942 5.01616 3.5788 6.63542C2.54819 8.25467 2.0003 10.1372 2 12.06Z"
            fill="#B6B6B6"
          />
        </g>
      </g>
    </svg>
  );
};

export default Facebook;
