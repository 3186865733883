import React from 'react';
import Link from 'next/link';
import Box from 'common/components/Box';
import {
  FooterWrapper,
  List,
  ListItem,
  FooterContainer,
  TitleInfo,
  ContentSocialMedia,
  Encript,
} from './footer.style';
import LogoSwiftask from 'common/assets/image/logo/swiftask';
import Facebook from 'common/assets/image/logo/social/facebook';
import Linkedin from 'common/assets/image/logo/social/linkedin';
import Twitter from 'common/assets/image/logo/social/twitter';
import Youtube from 'common/assets/image/logo/social/youtube';
import { FOOTER_WIDGET } from 'common/data/SaasModern';
import { translation } from '../../../../translation/translation';

const Footer = ({ locale }) => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <Box>
          <LogoSwiftask />
          <ContentSocialMedia>
            <Link href='https://www.facebook.com/swiftask' target='_blank'>
              <Facebook />
            </Link>
            <Link href='https://x.com/swiftask_ai' target='_blank'>
              <Twitter />
            </Link>
            <Link href='https://www.youtube.com/@Swiftask' target='_blank'>
              <Youtube />
            </Link>
            <Link href='https://linkedin.com/company/swiftask' target='_blank'>
              <Linkedin />
            </Link>
          </ContentSocialMedia>
          <Encript>{translation.encrypt[locale]}</Encript>
        </Box>
        {/* End of footer logo column */}
        {FOOTER_WIDGET.map((widget, index) => (
          <Box className='col' key={`footer-widget-${index}`}>
            <TitleInfo>{widget.title[locale]}</TitleInfo>
            <List>
              {widget.menuItems.map((item, index) => {
                if (item.blank) {
                  return (
                    <ListItem key={`footer-list-item-${index}`}>
                      <Link href={item.url} passHref legacyBehavior>
                        <a target='_blank' className='ListItem'>
                          {item.text[locale]}
                        </a>
                      </Link>
                    </ListItem>
                  );
                }

                return (
                  <ListItem key={`footer-list-item-${index}`}>
                    <Link href={item.url} legacyBehavior={true}>
                      <a className='ListItem'>{item.text[locale]}</a>
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        ))}
        {/* End of footer List column */}
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
