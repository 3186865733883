import React from 'react';

const Twitter = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="twitter-ic">
        <g id="Group">
          <path
            id="Vector"
            d="M23.6435 4.93699C22.8085 5.30699 21.9115 5.55699 20.9685 5.66999C21.9415 5.08778 22.6694 4.17145 23.0165 3.09199C22.1023 3.63497 21.1018 4.01718 20.0585 4.22199C19.3569 3.47285 18.4276 2.97631 17.4148 2.80946C16.4021 2.64261 15.3626 2.81478 14.4577 3.29924C13.5529 3.7837 12.8333 4.55335 12.4107 5.48869C11.988 6.42403 11.886 7.47274 12.1205 8.47199C10.2682 8.37898 8.45613 7.89753 6.80192 7.05889C5.14772 6.22025 3.68834 5.04315 2.5185 3.60399C2.1185 4.29399 1.8885 5.09399 1.8885 5.94599C1.88805 6.71298 2.07693 7.46823 2.43838 8.14472C2.79982 8.82121 3.32266 9.39802 3.9605 9.82399C3.22078 9.80045 2.49737 9.60057 1.8505 9.24099V9.30098C1.85043 10.3767 2.22253 11.4194 2.90368 12.252C3.58483 13.0846 4.53307 13.6559 5.5875 13.869C4.90128 14.0547 4.18184 14.0821 3.4835 13.949C3.781 14.8746 4.3605 15.684 5.14087 16.2639C5.92124 16.8438 6.86342 17.1652 7.8355 17.183C6.18533 18.4784 4.14739 19.1811 2.0495 19.178C1.67788 19.1781 1.30658 19.1564 0.9375 19.113C3.06698 20.4822 5.54584 21.2088 8.0775 21.206C16.6475 21.206 21.3325 14.108 21.3325 7.95199C21.3325 7.75199 21.3275 7.54998 21.3185 7.34999C22.2298 6.69096 23.0164 5.87488 23.6415 4.93999L23.6435 4.93699Z"
            fill="#B6B6B6"
          />
        </g>
      </g>
    </svg>
  );
};

export default Twitter;
